<template>
  <reemplazoForm
    btnSubmit="Crear Reemplazo"
    :reemplazo="data"
    @processForm="agregar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import reemplazoForm from './ReemplazosForm.vue'

export default {
  components: {
    reemplazoForm,
  },
  data() {
    return {
      data: {
        fecha_inicio: '',
        fecha_termino: '',
        id_persona_rol_docente: '',
        id_persona_rol_reemplazo: '',
      },
    }
  },
  methods: {
    ...mapActions({
      createReemplazo: 'reemplazos/addReemplazo',
     }),
    agregar(reemplazo) {
      this.createReemplazo(reemplazo).then(() => {
        const statusReemplazo = store.state.reemplazos.status
        if (statusReemplazo === 'success') {

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Reemplazo creado 👍',
              icon: 'CheckIcon',
              text: `El reemplazo fue guardado con éxito!`,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 6000,
          })

          this.$router.replace({
            name: 'reemplazos',
          })
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.reemplazos.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}

</script>
